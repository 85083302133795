import React from 'react';
import { Spinner } from 'wix-ui-tpa/cssVars';
import { classes } from './loader.st.css';
import { useApi } from '~/components/CollectionWidget/Widget/components/api-provider/use-api';

export const Loader: React.FC = () => {
  const { baseParams } = useApi((state) => ({
    baseParams: state.baseParams,
  }));
  return (
    <div className={classes.root}>
      <Spinner isStatic={baseParams.isScreenshotMode} />
    </div>
  );
};
