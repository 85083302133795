import React from 'react';
import { ApiContext } from './api-context';
import { CollectionWidgetProps } from '../../../common/types';

export const ApiProvider: React.FC<React.PropsWithChildren<CollectionWidgetProps>> = ({
  children,
  ...props
}) => {
  return (
    <ApiContext.Provider
      value={{
        type: 'READY',
        context: props,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};
