import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';
import {
  FormStylesParams,
  reviewFormStylesParams,
} from '~commons/components/review-form/Settings/stylesParams';

export type AllStylesParams = {
  productPreviewTitleFont: StyleParamType.Font;
  productPreviewTitleColor: StyleParamType.Color;
} & FormStylesParams;

const styleParams = createStylesParams<AllStylesParams>({
  productPreviewTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-L'),
  },
  productPreviewTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  ...reviewFormStylesParams,
});

export type StylesParams = typeof styleParams;

export default styleParams;
