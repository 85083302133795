import { FormErrorType } from '../../../common/store/form/form-types';
import { ReviewCreateError } from '../../../common/types';
import { unreachable } from '~/ts-utils';

export const buildReviewCreateError = (formError: FormErrorType): ReviewCreateError => {
  switch (formError) {
    case 'REVIEW_ALREADY_EXISTS':
      return {
        type: 'TRANSLATION',
        key: 'toast.error-review-publish-duplicate',
      };
    case 'CONTACT_BELONGS_TO_MEMBER':
      return {
        type: 'MEMBER_CONTACT_EXISTS_ERROR',
      };
    case 'PERMISSION_DENIED':
      return {
        type: 'TRANSLATION',
        key: 'toast.error-review-publish-permission',
      };
    case 'INVALID_REQUEST_LINK':
      return {
        type: 'TRANSLATION',
        key: 'toast.error-review-publish-invalid-request-link',
      };
    case 'UNKNOWN':
      return {
        type: 'TRANSLATION',
        key: 'toast.error-review-publish-unknown',
      };
    default:
      throw unreachable(formError);
  }
};
