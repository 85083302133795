import { useContextSelector } from '~commons/use-context-selector';
import { isShallowEqual } from '~commons/services/is-shallow-equal';
import { ApiContext } from './api-context';
import { State } from '../../../common/store/types';
import { ControllerActions } from '../../../controller/controller-actions';

type RuntimeMapper<T> = (state: State, actions: ControllerActions) => T;

/**
 * @internal
 * Optimized hook which will trigger rerender only if result is not shallowly equal.
 * Avoid creating new values in it. Don't:
 * const { myNevVal } = useApi(state => ( { nyNewVal: () => state.something }));
 */
export function useApi<T>(mapRuntimeToProps: RuntimeMapper<T>): T {
  return useContextSelector(
    ApiContext,
    (ctx) => {
      if (ctx.type !== 'READY') {
        throw new Error('useApi used outside ApiContext or it was uninitialized');
      }
      const { context } = ctx;
      return mapRuntimeToProps(context.state, context.actions);
    },
    isShallowEqual,
  );
}
