import { createContext } from '~commons/use-context-selector';
import { State } from '../../../common/store/types';
import { ControllerActions } from '../../../controller/controller-actions';

type ApiType =
  | {
      type: 'READY';
      context: {
        state: State;
        actions: ControllerActions;
      };
    }
  | { type: 'NULL' };

export const ApiContext = createContext<ApiType>({ type: 'NULL' });
