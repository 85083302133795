import React from 'react';
import { ReviewForm } from '~commons/components/review-form/review-form';
import { useApi } from '../api-provider/use-api';
import { useFormSettings } from '~commons/hooks/use-form-settings';
import { ReviewContent } from '~commons/types';
import { ROOT } from './data-hooks';
import { buildReviewCreateError } from './error-builder';
import { AppReadyState } from '../../../common/store/app-state/app-state-types';
import { uploadMediaFile } from '~commons/upload-media';

export const DEFAULT_REVIEW_CONTENT: ReviewContent = {
  title: '',
  body: '',
  rating: 0,
  media: [],
};

export const CollectionForm: React.FC<{ appState: AppReadyState }> = ({ appState }) => {
  const { formState, createReview, requestLogin, instance, isReviewRequest } = useApi(
    (state, actions) => ({
      instance: state.baseParams.instance ?? '',
      isReviewRequest: state.baseParams.origin.type === 'REVIEW_REQUEST',
      formState: state.form,
      createReview: actions.createReview,
      requestLogin: actions.requestLoginAction,
    }),
  );
  const { configuration, currentMember } = appState;
  const isFormPending = formState.status === 'PUBLISHING';
  const formSettings = useFormSettings({
    configuration,
    contactSettings: {
      name: { enabled: true },
      email: { enabled: !isReviewRequest && !currentMember },
    },
  });

  const uploadMediaFileFn = React.useMemo(() => uploadMediaFile({ instance }), [instance]);

  return (
    <ReviewForm
      id="review-collection-form"
      isEdit={false}
      isPending={isFormPending}
      onSubmit={({ content, contact }) => {
        createReview({
          content,
          createAs: contact
            ? {
                type: 'VISITOR',
                contact,
              }
            : {
                type: 'MEMBER',
              },
        });
      }}
      formSettings={formSettings}
      initialContent={{ ...DEFAULT_REVIEW_CONTENT, name: currentMember?.profile?.nickname ?? '' }}
      onLoginClick={() => {
        requestLogin();
      }}
      onMediaUpload={uploadMediaFileFn}
      dataHook={ROOT}
      {...(formState.status === 'ERROR'
        ? { error: buildReviewCreateError(formState.errorType) }
        : {})}
    />
  );
};
