import React from 'react';
import styles from '../../styles/collection-styles.scss';

const AppWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <section className={styles.collectionWidget} tabIndex={-1}>
      {children}
    </section>
  );
};

export default AppWrapper;
