import React from 'react';
import { Text, Button } from 'wix-ui-tpa/cssVars';
import { useTranslate } from '../../../hooks/use-translate';
import { classes } from './fallback-state.st.css';

export const FallbackState: React.FC<{ errorCode?: string | null }> = ({ errorCode }) => {
  const t = useTranslate();
  return (
    <div className={classes.root}>
      <Text>{t('error-boundary-fallback.message')}</Text>
      <Button onClick={() => window.location.reload()}>
        {t('error-boundary-fallback.button-label')}
      </Button>
      {errorCode && <Text className={classes.error}>ErrorId: {errorCode}</Text>}
    </div>
  );
};
